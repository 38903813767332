.pt-label-inner {
  position: relative;
  height: 86px;
  border-bottom: 1px solid #333333;
}

.pt-label-wrap {
  width: 80px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #c5c5c5;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  bottom: 28px;
  left: -6px;
  transform: rotate(-45deg);
}

.pt-label-text {
  width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-left: 2px;
}

.pt-box-disabled {
  font-size: 17px;
  margin-right: 4px;
}

.pt-box-enabled {
  font-size: 19px;
  margin-right: 4px;
}
