.filterBtn {
  --background: #414040;
  --background-focused: #191919;
  --border-color: #191919;
  --border-radius: 3px;
  --border-width: 1px;
  width: 45px;
  height: 45px;
  margin: 4px;
}

.filterBtn::part(native) {
  padding: 0px;
}

.active-toggle {
  --background: #191919;
  --border-color: #72ffff;
  --border-width: 2px;
}

@media (min-width: 700px) {
  .filterBtn {
    flex-basis: 50px;
    max-width: 50px;
    min-width: 40px;
    flex-grow: 1;
    flex-shrink: 1;
  }
}
