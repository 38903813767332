.pv-ion-content {
  --padding-bottom: 136px;
}

.pv-ion-content-landscape {
  --padding-bottom: 76px;
}

.pv-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.pv-row {
  display: flex;
  background-color: #414040;
}

.pv-col {
  min-width: 60px;
  max-width: 60px;
  background-color: #414040;
}

.pv-col:not(:first-of-type) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pv-header-col {
  border-right: 1px solid #333;
  background-color: #414040;
}

.pv-header-row {
  height: 86px;
  width: 100vw;
}

.pv-header-row .pv-header-col,
.pv-header-row .pv-col {
  border-bottom: 1px solid #333;
}

.pv-row:first-of-type .pv-header-col,
.pv-row:first-of-type .pv-col {
  border-bottom: 1px solid #333;
}

.pv-col.pv-col-spacer {
  min-width: 24px;
  max-width: 24px;
}

.edit-order-btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  height: 60px;
  margin-top: 25px;
}

@media (min-width: 700px) {
  .pv-layout {
    flex-direction: row;
  }
}
