.blockWrap {
  height: 100%;
  width: 60px;
  height: 76px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 16px;
}

.block {
  background-color: #9c9c9c;
  border-radius: 3px;
  transition: all 0.06s linear;
}
