.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 80px;
}

.profile-select {
  color: #191919;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  width: 72px;
  height: 80px;
}

.profile-overlay {
  width: calc(100vw + 200px);
  height: 100%;
  position: absolute;
  top: 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
}

.profile-select-panel {
  box-shadow: 23px 0px 56px 0px #00000036;
  background: #414040;
  overflow: scroll;
  padding: 8px 24px 0px 24px;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 300px;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.active-profile {
  border-bottom: 1px solid #333333;
  padding-bottom: 8px;
}

.profile-select-list {
  padding-top: 12px;
}

.user-label {
  --color: #f2f2f2;
  --color-hover: #f2f2f2;
  --color-focused: #f2f2f2;
  --padding-start: 0px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  font-size: 18px;
  line-height: 21px;
}

.profile-icon {
  align-items: center;
  color: #191919;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  margin-right: 8px;
  height: 43px;
  width: 43px;
}

.version-label {
  color: #f2f2f2;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

.upload-section {
  text-align: center;
  margin-bottom: 20px;
}

@media (min-width: 700px) {
  .profile {
    width: 80px;
    height: 72px;
  }

  .profile-select {
    width: 80px;
    height: 72px;
  }
}
