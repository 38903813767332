.order-drawer-header {
  height: 72px;
  width: 100%;
  background-color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 16px;
}

.menu-close {
  --color: #ff6e4f;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: none;
}

.menu-reset {
  --color: #f2f2f2;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: none;
}

.empty-order {
  padding: 32px;
}

.empty-order-header {
  color: #ffffff;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin: 0px;
}

.empty-order-body {
  color: #c5c5c5;
  font-size: 18px;
  line-height: 21px;
  margin-top: 8px;
}

.fixed-content {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  display: block;
  margin-bottom: 140px;
}

.content-drawer {
  background: #414040;
  color: #f2f2f2;
  height: 100%;
  display: flex;
}

.tab {
  --background-activated: none;
  --background-focused: none;
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 16px;    /* padding inside buttons in portrait mode */
  color: #c5c5c5;
  font-size: 24px;
  height: 60px;
  line-height: 28px;
  margin: 0px;
  margin-right: auto;
  text-transform: none;
  width: 100%;
}

.active-tab {
  color: #f2f2f2;
  font-weight: 700;
}

.tab-name {
  /* left-align buttons when in portrait mode */
  text-align: left;
  width: 100%;
}

.active-tab .tab-name {
  border-bottom: 4px solid #72ffff;
  padding-bottom: 5px;
}

.checkbox {
  --background: #414040;
  --background-checked: #72ffff;
  --border-color: #f2f2f2;
  --border-color-checked: #72ffff;
  --checkmark-color: #012c2c;
  margin-right: 8px;
}

.checkbox-label {
  --color-checked: #f2f2f2;
  --color-hover: #f2f2f2;
  --color-focused: #f2f2f2;
  color: #f2f2f2;
  font-size: 18px;
  line-height: 21px;
}

.drawer-footer {
  position: absolute;
  bottom: 0;
  border-top: 1px solid #333333;
}

.drawer-footer-toolbar {
  --background: #414040;
  text-align: center;
}

.footer-buttons {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: none;
  width: 160px;
}

.cancel {
  --color: #f2f2f2;
  --border-color: #f2f2f2;
  font-weight: 700;
  margin: 16px 8px 16px 8px;
}

.save {
  --color: #012c2c;
  --background: #72ffff;
  font-weight: 700;
  margin: 16px 8px 16px 8px;
}

.tab-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  padding-bottom: 20px;
  overflow: scroll;
  flex-shrink: 0;
  width: 200px;
}

.values-column {
  overflow: scroll;
  flex-grow: 1;
  margin-top: 10px;
}

.item-list {
  display: flex;
  flex-direction: column;
}

.reset-toast {
  --background: #191919;
  --border-color: #191919;
  --button-color: #f2f2f2;
  --color: #f2f2f2;
  box-shadow: 23px 0px 56px rgba(0, 0, 0, 0.21);
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

@media (min-width: 1024px) and (min-aspect-ratio: 3/2) {
  .tab-column {
    flex-direction: row;
    border-bottom: 1px solid #333333;
    gap: 0px;
    height: 40px;
    padding-bottom: 0px;
    width: 100%;
  }

  .values-column {
    margin: 8px;
  }

  .item-list {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .item-list > ion-item {
    width: 33%;
  }

  .fixed-content {
    flex-direction: column;
    gap: 0px;
    margin-left: 6px;
  }

  .tab {
    height: 36px;
    margin-right: 0px;
  }

  .content-drawer {
    flex-direction: column;
  }
  .tab-name {
    margin-right: auto;
    width: auto;
  }
}