.tables-header {
  background: #333333;
  color: #f2f2f2;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  height: 72px;
  padding: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clear-selection {
  --color: #f2f2f2;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: none;
}
