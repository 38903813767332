.tableList {
  display: flex;
}

.table-button-group {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 0px 16px 0px 16px;

  overflow: scroll;
}

.table-button {
  --color: #d9d9d9;
  --border-color: #414040;
  --border-radius: 50%;
  --border-width: 1px;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin: 0px;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
}

.table-active {
  --color: #f2f2f2;
  --border-color: #72ffff;
  --border-width: 3px;
  font-weight: 700;
}

.add-table {
  --background: #4d4d4d;
  --border-radius: 50%;
  width: 64px;
  height: 64px;
  margin: 0px;
  flex-shrink: 0;
}

@media (min-width: 700px) {
  .table-button-group {
    flex-direction: column;
    height: 100%;
    width: 80px;
    margin: 0px;
    padding: 16px 0px 16px 0px;
  }
}
