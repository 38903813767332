.pt-wrap {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.pt-wrap table {
  border-spacing: 0;
  height: 100%;
}

.pt-wrap th {
  border-left: none;
  height: 86px;
  width: 60px;
  min-width: 60px;
  position: sticky;
  top: 0;
  background: #414040;
}

.pt-wrap td {
  border-left: none;
  width: 60px;
  min-width: 60px;
  vertical-align: baseline;
}

.pt-wrap th:nth-child(1),
.pt-wrap td:nth-child(1) {
  position: sticky;
  left: 0;
  width: 100%;
  min-width: 377px;
}

.pt-wrap td:nth-child(1) {
  background: #414040;
  vertical-align: inherit;
  z-index: 4;
}

.pt-wrap th:nth-child(1) {
  z-index: 5;
}

.pt-header-gap {
  border-bottom: 1px solid #333333;
  height: 100%;
}

.pt-label-th {
  z-index: 4;
  width: 60px;
}

.pt-suggestion-item-wrap {
  height: 100%;
  border-right: 1px solid #333333;
  padding-top: 16px;
  min-height: 76px;
}

.pt-suggestion-grid {
  display: grid;
  grid-template-columns: 24px auto 64px;
  grid-template-rows: auto;
  row-gap: auto;
  column-gap: 8px;
  margin-left: 8px;
  margin-right: 1px;
  padding-right: 16px;
}

.pt-suggestion-grid-landscape {
  grid-template-columns: 24px auto 60px;
}

.pt-pill-wrap {
  width: auto;
  height: 48px;
  display: flex;
}

.pt-pill {
  border-radius: 3px;
  overflow: hidden;
  width: 24px;
  height: 48px;
}

.pt-pill div {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pt-pill .type {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #353b2e;
}

.pt-pill .stock {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #c5c5c5;
  background-color: #333;
}

.pt-group-pill .stock {
  font-size: 17px;
  line-height: 18px;
  color: #c5c5c5;
  font-weight: bold;
  background: none;
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 4px;
}

.pt-pill-expanded {
  height: 24px;
}

.pt-pill-expanded div {
  height: 100%;
}

.pt-drinkstyle-header {
  display: flex;
}

.pt-group-pill {
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  height: 24px;
  margin-left: 8px;
}

.pt-group-pill.pt-group-single {
  border-style: none;
}

.pt-group-pill div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pt-drinkstyle-name {
  color: #f2f2f2;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  display: inline-block;
  vertical-align: top;
  margin-top: 1px;
}

.pt-traits {
  color: #c5c5c5;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
}

.pt-traits img {
  height: 14px;
  margin-right: 12px;
  vertical-align: middle;
}

.pt-traits ul {
  padding-left: 0px;
  margin: 0;
  margin-top: 3px;
}

.pt-traits ul li {
  list-style: none;
  display: inline;
  vertical-align: middle;
}
.pt-traits ul li:after {
  content: " \00b7";
  font-size: 44px;
  vertical-align: middle;
  margin-right: 6px;
  margin-left: -3px;
  color: #727272;
}

.pt-traits ul li:last-child:after {
  content: none;
}

.pt-prices {
  display: flex;
  width: 64px;
  justify-content: space-between;
  text-align: right;
  color: #f2f2f2;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

.pt-pair-block-wrap {
  height: 100%;
}

.pt-table-fill {
  height: 100%;
}

.pt-table-fill td:first-of-type {
  border-right: 1px solid #333333;
}

.select-size span, .select-size div {
	color:#f2f2f2 !important;
}
