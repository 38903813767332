.table-prompt {
  flex-grow: 1;
}

.message-header {
  color: #ffffff;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-top: 150px;
  margin-bottom: 0px;
  text-align: center;
}

.message-body {
  color: #c5c5c5;
  font-size: 18px;
  line-height: 21px;
  margin: 8px;
  text-align: center;
}

.message-add-table {
  --background: #4d4d4d;
  --border-radius: 50%;
  display: block;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  width: 64px;
  height: 64px;
}
