.order-drawer {
  height: 72px;
  width: 100%;
  background-color: #333333;
  align-items: center;
  justify-content: space-between;
  padding: 22px 16px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.active-table {
  color: #f2f2f2;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.order-profile {
  display: flex;
  align-content: center;
  align-items: center;
}

.menu-table-buttons {
  display: flex;
  justify-content: flex-end;
}

.order-panel {
  max-height: 0px;
  overflow-y: hidden;
  transition: ease-in-out 200ms max-height;
  z-index: 10;
}

.order-panel.opened {
  max-height: 100%;
  transition: ease-in-out 200ms max-height;
}

.order-panel-content {
  background: #333333;
  height: 100%;
  padding: 16px;
  overflow: scroll;
  width: 100%;
}

.panel-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-label {
  color: #f2f2f2;
  font-size: 18px;
  line-height: 21px;
}

.order-section {
  margin-bottom: 40px;
}

.order-section:last-child {
  margin-bottom: 16px;
}

.section-content {
  margin-bottom: 0px;
}

.tag-content {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.tag-toggle {
  color: #c5c5c5;
  font-size: 18px;
  line-height: 21px;
  margin: 0px;
  text-decoration: underline;
  text-transform: none;
}

.no-results {
  color: #c5c5c5;
  font-size: 18px;
  line-height: 21px;
}

.no-results span {
  text-transform: lowercase;
}

.item-tag {
  --border-color: #191919;
  --border-radius: 45px;
  --border-width: 1px;
  --color: #f2f2f2;
  --padding-top: 8px;
  --padding-bottom: 8px;
  --padding-start: 10px;
  --padding-end: 10px;
  font-size: 18px;
  height: fit-content;
  line-height: 21px;
  margin: 0px;
  text-transform: none;
}

.tag-text {
  white-space: normal;
  text-align: left;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 18px;
  line-height: 21px;
}

.active-tag {
  --background: #191919;
  --border-color: #72ffff;
  --border-width: 2px;
}

.bin-label {
  color: #979797;
}

.order-panel-icon {
  width: 33%;
}

.order-drawer-button {
  height: 72px;
  width: 150px;
}

.order-panel-icon ion-button::part(native) {
  padding: 0px;
}

.order-drawer-button-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@media (min-width: 700px) {
  .order-drawer {
    width: 72px;
    height: 100%;
    padding: 0px;
    flex-direction: column;
  }

  .order-drawer-button {
    width: 72px;
    height: 150px;
  }

  .order-drawer-button img {
    max-width: none;
  }

  .order-panel {
    max-width: 0px;
    transition: ease-in-out 200ms max-width;
  }

  .order-panel.opened {
    max-height: 100%;
    max-width: 400px;
    transition: ease-in-out 200ms max-width;
  }

  .order-panel-content {
    min-width: 250px;
  }

  .order-panel-icon {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 72px;
    text-align: center;
    height: 33%;
  }

  .order-drawer-button-right {
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    width: 100%;
  }

  .align-bottom {
    display: flex;
    align-items: flex-end;
  }
}
