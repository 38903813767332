.info {
  margin-top: 11px;
  display: flex;
  padding: 0 57px;
  color: #f2f2f2;
  margin-bottom: 18px;
}

.info .label {
  color: #c5c5c5;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 5px;
}

.suggestion-detail-row {
  margin: 12px 6px 12px 24px;
  /* border: 1px solid blue; */
  display: grid;
  grid-template-columns: 24px auto 68px;
  grid-template-rows: auto;
  column-gap: 8px;
  row-gap: auto;
  align-items: flex-start;
}

.suggestion-detail-row .drink-name {
  font-size: 18px;
  line-height: 21px;
  color: #f8f8f8;
  font-weight: 400;
}

.suggestion-detail-row .drink-bin {
  font-size: 18px;
  line-height: 21px;
  color: #c5c5c5;
  font-weight: 400;
}
