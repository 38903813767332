.filters {
  display: flex;
  gap: 32px;
  padding: 16px 12px 12px 12px;
  border-bottom: 1px solid #333333;
  justify-content: space-between;
  z-index: 10;
  position: relative;
  background: #414040;
}

.filtersType {
  max-width: 160px;
}

.filtersPrice,
.filtersTrait,
.filtersSize {
  min-width: 106px;
}

@media (min-width: 700px) {
  .filters {
    gap: 8px;
  }

  .filtersType {
    max-width: none;
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
  }

  .filtersPrice {
    min-width: 120px;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .filtersTrait,
  .filtersSize {
    min-width: unset;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}
