.navbar {
  background-color: #191919;
  display: flex;
  flex-direction: row;
  z-index: 10;
}

.main-content {
  height: auto;
  top: 80px;
}

@media (min-width: 700px) {
  .navbar {
    flex-direction: column;
    width: 80px;
    height: 100%;
  }

  .main-content {
    top: 0;
    left: 80px;
  }
}
