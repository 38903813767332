.pt-suggestions {
  height: 100%;
  padding: 0 2px 16px 24px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: #c5c5c5;
  border-bottom: 1px solid #333333;
  border-right: 1px solid #333333;
  z-index: 5;
}

.pt-icons {
  width: 80px;
  display: flex;
  justify-content: space-around;
  margin-right: -2px;
}

.pt-icons-landscape {
  width: 96px;
  margin-right: 2px;
}
